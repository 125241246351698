export type ShopRates = {
  bodyLabor: number;
  dailyStorage: number;
  diagnosticLabor: number;
  electricalLabor: number;
  estimateFee: number;
  frameLabor: number;
  glassLabor: number;
  mechanicalLabor: number;
  paintLabor: number;
  paintSupplies: number;
  pdrLabor: number;
  structuralLabor: number;
  mediumDutyMechanical: number;
  mediumDutyPaintBody: number;
  discountType: string;
  partsPercentage: number;
  aemPricing: number;
  oemUsed: number;
};

export const ShopRatesProperties = [
  "bodyLabor",
  "dailyStorage",
  "diagnosticLabor",
  "electricalLabor",
  "estimateFee",
  "frameLabor",
  "glassLabor",
  "mechanicalLabor",
  "paintLabor",
  "paintSupplies",
  "pdrLabor",
  "structuralLabor",
  "mediumDutyMechanical",
  "mediumDutyPaintBody",
  "discountType",
  "partsPercentage",
  "aemPricing",
  "oemUsed",
];

export enum ShopStatus {
  NEW = "New",
  NEEDS_TRIAL_CAR = "Needs a Trial Car",
  ACTIVE_UNSIGNED = "Active Shop - Contract Unsigned",
  ACTIVE = "Closed won - Active Shop",
}

export const SERVICE_CATEGORIES = {
  AUTOBODY_DAMAGE: "Autobody Damage",
  GLASS: "Glass",
  PAINTLESS_DENT_REPAIR: "Paintless Dent Repair",
  OIL_CHANGE: "Oil Change Services & Fluids",
  FLUIDS: "Oil Change Services & Fluids",
  AC_RECHARGE: "Maintenance & Inspections",
  SCHEDULED_MAINTENANCE: "Maintenance & Inspections",
  TIRE_ROTATION: "Tire & Wheel Services",
  WHEEL_ALIGNMENT: "Tire & Wheel Services",
  TIRES: "Tire & Wheel Services",
  TRANSMISSION: "Transmission Services",
  SUSPENSION: "Suspension & Steering",
  GENERAL_DIAGNOSTICS: "Automobile Diagnostics",
  BELTS: "Belts",
  MUFFLERS: "Mufflers & Catalytic Converters",
  BATTERY: "Battery Services",
  ENGINE_REPAIR: "Engine Repair & Services",
  BRAKE_PADS: "Brake Services",
  OTHER: "Other",
  SERVICE_MANAGER_CONSULTATION: "Other",
};

export const SERVICE_CATEGORIES_ARRAY = [
  ...new Set(Object.values(SERVICE_CATEGORIES)),
];

export enum BusinessHoursDayOfWeek {
  Sunday = "sunday",
  Monday = "monday",
  Tuesday = "tuesday",
  Wednesday = "wednesday",
  Thursday = "thursday",
  Friday = "friday",
  Saturday = "saturday",
}

export const BusinessHoursDayOfWeekValues = Object.values(
  BusinessHoursDayOfWeek
).map((s) => s.toString());

export const isBusinessHoursDayOfWeek = (
  dayOfWeek: string
): dayOfWeek is BusinessHoursDayOfWeek => {
  return BusinessHoursDayOfWeekValues.includes(dayOfWeek);
};

export enum ShopOemPartsPricing {
  CostPlus = "Cost Plus",
  ListMinus = "List Minus",
  AsInvoiced = "As Invoiced",
}

const ShopOemPartsPricingStrings = Object.values(ShopOemPartsPricing).map((s) =>
  s.toString()
);

export const isOemPartsPricing = (
  value: string
): value is ShopOemPartsPricing => {
  return ShopOemPartsPricingStrings.includes(value);
};
