export async function signInWithEmailAndPassword(
  email: string,
  password: string
) {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, password }),
  };

  const response = await fetch(
    `${process.env.NEXT_PUBLIC_APIV2_URL}/auth/shops/token`,
    options
  );
  const body = await response.json();
  if (!response.ok) {
    console.error(
      "signInWithEmailAndPassword() error response from backend:",
      body
    );
    throw new Error(body.message);
  }
  return body.token;
}
